/* .dayBg {
    background-color: #ffffff; 
  }
  
  .dayBgOffRange {
    background-color: #ddd; 
    cursor: pointer ;
  }
  
  .todayOffRange {
    background-color: #ddd ;  
  }
   */
  .eventCustom {
    background-color: #F5F5F5;  /* Coral color */
    border: none;
    color: #000;
    display: flex;
    justify-content: center;
    padding: 5px 10px;
    border-radius: 0;
    height: 77px;
    margin: -2px -1px 0px 0;
 }

 .dayWithEvent {
    background-color: #F5F5F5; 
  }
  /* .rbc-calendar {
    unicode-bidi: bidi-override;
  }
   */
  .calendarContainer {
    direction: ltr; 
  }
  
  .pastDay {
    pointer-events: none;
    opacity: 0.5;
    cursor: auto !important;
  }
  
  .pastEvent {
    pointer-events: none;
    cursor: auto !important;
  }