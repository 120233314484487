/* custom-calendar.css */

  
  .rbc-show-more {
    cursor: pointer;
  }
  .rbc-day-bg{
    cursor: pointer;
  }

  /* NoticeCalendar.module.css */
.off-day {
  background-color: #eee !important; /* Light gray for off days */
}

.available-day {
  background-color: #fff !important; /* White for available days */
  cursor: pointer;
}

.unavailable-day {
  background-color: #eee !important; /* Light gray for unavailable days */
}
