.Login {
  background-color: #EFF5FF;
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  text-align: center;
}

.custom-box {
  direction: rtl;
  display: flex;  
  justify-content: center;
  align-items: center;
  z-index: 1;
  width: 300px;
  /* height: 400px; */
  padding: 22px 56px; 
  border-radius: 32px;
  box-shadow: 0 8px 32px 0 #4285F426; 
  background: #FFFFFF;
}

.top-left-light {
  position: absolute;
  top: -180px;
  left: -50px;
}

.top-left-dark {
  position: absolute;
  top: -180px;
  left: -200px;
}

.bottom-right-light {
  position: absolute;
  bottom: -180px;
  right: -50px;
}

.bottom-right-dark {
  position: absolute;
  bottom: -180px;
  right: -200px;
}

.forgot-password-link {
  color: darkgray;
}

.forgot-password-link:hover {
  color: rgb(48, 48, 48); 
}

.icon {
  height: 38px;
  width: 204px;
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 4px 0;
}

.separator::before,
.separator::after {
  content: "";
  flex-grow: 1;
  border-bottom: 1px solid #ccc;
}

.separator-text {
  margin: 0 10px;
  color: #000;
}

.create-account {
  margin-top: 10px;
  color: black;
}

.create-account a {
  color: #1890ff;
  text-decoration: none;
}

.create-account a:hover {
  text-decoration: underline;
}

:where(.css-dev-only-do-not-override-3rel02).ant-input-affix-wrapper-lg {
  padding: 5px 11px;
} 
