.footer-container{
    display: flex;
    flex-direction: row;
    height: 382px;
    padding: 10px;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
    align-self: stretch;
    background: #F9F9F9;
    direction: rtl;
}

.footer-div{
    padding: 20px 80px;
}

@media (max-width: 500px) {
    .footer-container{
        display: flex ;
        flex-direction: column;
        align-items:flex-start;
        gap: 32px;
    }   
    .footer-div{
        padding: 20px 40px;
    }
}