.feature-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    border-radius: 4px;

  }
  
  .feature-item-icon {
    width: 12px;
    height: 12px;
  }
  
  .feature-item-text-light {
    color: #0c0047;
    text-align: right;
    font-family: "Nunito Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.084px;
    width: 306px;
    margin-right: 5px;
  }
  
  .feature-item-text-dark {
    color: #fff;
    text-align: right;
    font-family: "Nunito Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; 
    letter-spacing: 0.084px;
    width: 306px;
    margin-right: 5px;
  }
  