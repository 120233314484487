.features-container{
    display: inline-flex ;
    align-items: center;
    gap: 32px;
    direction: rtl;
}

.features-card{
    width: 276px;
    height: 302px;
    flex-shrink: 0;
    border-radius: 15px;
    border: 1px solid #EAEAEA;
    background: #FFF;

}

.card-icon{
border-radius: 15px;
background: #F2F2FF;
width: 64px;
height: 56.186px;
flex-shrink: 0;
padding: 5 4 12px;
margin: 20px -100px 10px 70px;
}

.features-card-h4{
    color: #343434;
    margin-right: 22;
    text-align: right;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: -0.408px;
}
.features-card-p{
    color: #969696;
    padding: 0 22;
    text-align: right;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 170%; /* 23.8px */
    letter-spacing: -0.408px;
}
@media (max-width: 1024px) {
    .features-container{
        display: inline-flex ;
        align-items: center;
        gap: 32px;
        direction: rtl;
    }
    .features-card{
        width: 162.919px;
        height: 178.267px;
        border-radius: 8.854px;
        border: 0.59px solid #EAEAEA;
    
    }
    
    .card-icon{
        border-radius: 15px;
        background: #F2F2FF;
        width: 23.611px;
        height: 20.729px;
        margin: 8px 0 0px 94px;

    }
    
    .features-card-h4{
        color: #343434;
        text-align: right;
        font-family: "Nunito Sans";
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: 17.709px; /* 136.22% */
        letter-spacing: -0.241px;
    }
    .features-card-p{
        color: #969696;
        margin: -8px 0 0 0;
        text-align: right;
        font-family: "Nunito Sans";
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: 170%; /* 18.7px */
        letter-spacing: -0.241px;
        flex-shrink: 0;
    }
}


@media (max-width: 500px) {
    .features-container{
        display: flex ;
        flex-direction: column;
        align-items: center;
        gap: 32px;
    }   
}