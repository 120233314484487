.sidebar {
    color: #fff;
}
body {
  font-family: 'Nunito Sans', sans-serif;
}
.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px; /* Adjust the height as needed */
}
.iconAdmin {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px auto 0 auto; /* Center horizontally and add some vertical spacing */
}

.menu-bar{
    display: flex;
    flex-direction: column;
    height: 90.5vh;
    padding: 10px;
    gap: 15px;
    font-size: 1.2rem;
    position: relative;
}

.toggle-theme-btn {
    position: absolute;
    bottom: 10px;
    right: 10px;
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
}

.hide-sider-trigger .ant-layout-sider-trigger {
    display: none;
  }

  .content {
    height: 89.5vh;
    overflow: auto;
  }
  
  :where(.css-dev-only-do-not-override-3rel02).ant-picker-calendar .ant-picker-calendar-header {
    padding: 12px;
  }
  :where(.css-dev-only-do-not-override-3rel02).ant-card .ant-card-body {
    padding: 12px;
  }
  :where(.css-dev-only-do-not-override-3rel02).ant-picker-calendar .ant-picker-calendar-header .ant-picker-calendar-mode-switch {
    display: none;
  }

  :where(.css-dev-only-do-not-override-3rel02).ant-picker-calendar .ant-picker-content th, :where(.css-dev-only-do-not-override-3rel02).ant-picker-calendar .ant-picker-content td{
    border: 1px solid #7d7d775c;
  }

.card {
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    /* background: linear-gradient(135deg, #f5f5f5 0%, #e0e0e0 100%); */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .meet-date {
    font-size: 16px;
    color: #555;
    margin-bottom: 10px;
  }
  
  .meet-time {
    font-size: 20px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
    transition: color 0.3s ease;
  }
  
  .meet-title {
    font-size: 16px;
    color: #777;
  }
  
  .card:hover .meet-time {
    color: #1677ff;
    transform: scale(1.2) translateX(-20px);
    transition: transform 0.3s ease, color 0.3s ease;
  }
  
  .copy-btn{
    border-color: #ddd;
    color: #000;
  }
  .copy-btn:hover {
    background-color: #ccc !important; 
    border-color: #ccc !important; 
    color: #000 !important;
  }

  :where(.css-dev-only-do-not-override-3rel02).ant-picker-calendar.ant-picker-calendar-full .ant-picker-calendar-date {
    padding: 4px 0 0 ;
  }
  :where(.css-dev-only-do-not-override-3rel02).ant-picker-calendar.ant-picker-calendar-full .ant-picker-calendar-date-content {
    height: 80px;
    overflow: hidden;
  }

  .meeting-details-modal{
    background-color: #516FE9;
    right: calc(5% + 22px);
  }

  .main-modal{
    width: 260px !important;
    height: 235px !important;
    
  }

  :where(.css-dev-only-do-not-override-3rel02).ant-modal .ant-modal-content{
    padding: 8px 15px 16px 12px !important;

  }

  .detail-modal{
    width: 350px !important;
  }

  .appo-detail-icon{
    margin: 0 -23px 0 5px;
    font-size: 18px;
  }

  *{
    font-family: 'Nunito Sans', sans-serif !important;
  }

  .new-appointment-modal{
    width: 350px !important;
  }

  .card-appointment {
    border: 1px solid #ccc;
    background-color: #fff;
    border-radius: 5px; 
    overflow: hidden; /* Ensure content stays within card boundaries */
  }
  
  .card-upper-part {
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    padding: 16px 16px 0 16px;
    color: #fff;
    background-color: #1677ff;
    font: 16px bold;
    display: flex;
    justify-content: space-between;
  }
  
  .card-middle-lower-part {
    padding: 10px 16px 0 16px;
    
  }
  
  .card-middle-part {
    display: flex;
    justify-content: space-between;
  }
  
  .card-lower-part {
    max-height: 0;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    transition: max-height 0.3s ease-in 0.3s, border-top 0.3s ease-in 0.3s; /* Apply delay and transition */

  }
  
  .card-appointment:hover .card-lower-part {
    border-top: 1px solid #ccc;
    max-height: 50px; /* Adjust based on the height of your content */
  }
  
  .card-appointment:hover .card-middle-part {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .tabs {
    display: flex;
    margin-bottom: 10px;
  }
  
  .tabs button {
    padding: 10px 20px;
    margin-right: 5px;
    cursor: pointer;
    border: none;
    background-color: #f0f0f0;
    border-bottom: 2px solid transparent;
  }
  
  .tabs button:hover {
    background-color: #e0e0e0;
  }
  
  .tabs button:focus {
    outline: none;
  }
  
  .tabs button.active {
    border-bottom: 2px solid #007bff;
    background-color: #fff;
  }
  
  .appointments ul {
    list-style-type: none;
    padding: 0;
  }
  
  .appointments li {
    padding: 10px;
    border-bottom: 1px solid #ddd;
  }
  
  
  :where(.css-dev-only-do-not-override-3rel02).ant-tabs .ant-tabs-tab+.ant-tabs-tab {
    margin: 0 23px 0 0 ;
  }

  /* Add this to your CSS file */
.ant-list-item {
  cursor: pointer;
}

.ant-list-item:hover {
  background-color: #e6f7ff;
}

.ant-card {
  margin-bottom: 20px;
}


.upcoming-previous-reservations{
  padding: 16px 14px;
  margin: 8px;
}

.ant-modal-confirm-btns{
  direction: ltr;
}

.ant-modal-confirm-body-has-title{
  padding: 8px;
  margin: 8px;
}


:where(.css-dev-only-do-not-override-3rel02).ant-btn:not(:disabled):focus-visible{
  outline: 4px solid #aaa;
    outline-offset: 1px;
    transition: outline-offset 0s, outline 0s;
}