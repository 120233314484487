.background-img{
    margin: o;
    padding: o;
    background-image: url("../../assets/rightWhiteCircle.svg");
    background-repeat: no-repeat;
    background-size: 120% auto;
    background-position: 20% 0%;
}

.info-part{
    padding: 22;
    color: white;
}
.info-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
}
.info-part h1{
    color: #FFF;
    text-align: center;
    width: 916px;
    font-family: "Nunito Sans";
    font-size: 64px;
    font-style: normal;
    font-weight: 700;
    line-height: 81px; /* 126.563% */
    letter-spacing: 0.32px;
    text-transform: capitalize;
    margin: 8px;
}
.info-part p {
color: #FFF;
text-align: center;
font-family: "Nunito Sans";
font-size: 24px;
font-style: normal;
font-weight: 400;
line-height: 32px; /* 133.333% */
letter-spacing: -0.408px;
width: 910px;
}
body::-webkit-scrollbar {
    display: none; 
}
.info-btns{
    display: flex;
    justify-content: center;
    gap: 10px;
    margin: 0 0 0 -36px;
}

.info-img {
    width: 940px;
    margin-top: 40px;
    border-radius: 15px;
    box-shadow: 20px 20px 50px -12px rgba(0, 0, 0, 0.20);

  }

  .sections{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
}

.features-text{
    color: #0C0047;
    text-align: center;
    font-family: "Nunito Sans";
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.18px;
    text-transform: capitalize;
}
.bookly-text{
    color: #4880FF;
font-family: "Nunito Sans";
font-size: 36px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: 0.18px;
text-transform: capitalize;
}

.features-p{
    color: #969696;
font-family: "Nunito Sans";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: -0.408px;
}

.bookly-p{
    color: #4880FF;
font-family: "Nunito Sans";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: -0.408px;
}

.payment-img-container{
    background-image: url("../../assets/PayDesktop.svg");
    background-repeat: no-repeat;
    background-size: cover;

}
.payment-container{
 padding: 99 115 139 90;
 display: inline-flex;
 gap: 59px;
}

.dashboard-img{
    width: 630px;
    height: 448px;
    border-radius: 10.5px;
    background: #F8F9FD;  
}

.payment-text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: end;
}

.payment-text h1{
    color: #0C0047;
    text-align: right;
    font-family: "Nunito Sans";
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 78px */
    letter-spacing: 0.26px;
}
.payment-text h1 span{
    color: #4880FF;
}
.payment-text p{
    color: #969696;
    text-align: right;
    font-family: "Nunito Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 170%; /* 23.8px */
    letter-spacing: -0.408px;
}
.payment-text button{
    display: inline-flex;
    padding: 12px 45px;
    align-items: flex-start;
    gap: 10px;
    border-radius: 8px;
    background: #4880FF;
    width: fit-content;
    color: #FFF;
    font-family: "Nunito Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px; /* 157.143% */
    letter-spacing: -0.408px;
    border: none;
    direction: rtl;
}

.pricing h1{
    color: #0C0047;
text-align: center;
font-family: "Be Vietnam Pro";
font-size: 52px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: 0.26px;
}

.pricing p{
    color: #969696;
    text-align: center;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.41px;
}
.pricing-card-container{
    display: inline-flex;
    gap: 24px;
}

.pricing-card-white{
    display: flex;
    flex-direction: column;
    padding: 50px 24px;
    align-items: flex-start;
    gap: 10px;
    border-radius: 14px;
    background: #FFF;
    box-shadow: 0px 10px 56px 0px rgba(0, 0, 0, 0.05);
}
.pricing-card-white h1{
    width: 336px;
    color: #0C0047;
    text-align: center;
    font-family: "Be Vietnam Pro";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 133.333% */
}
.pricing-card-white p{
    width: 336px;
    color: #969696;
    text-align: center;
    font-family: "Be Vietnam Pro";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
}

.pricing-card-white h2{
    width: -webkit-fill-available;
    display: flex;
    justify-content: center;
    color: #0C0047;
    font-family: "Nunito Sans";
    font-size: 56px;
    font-style: normal;
    font-weight: 700;
    line-height: 60px; 
}
.pricing-card-white button{
    flex-shrink: 0;
    color: #FFF;
    text-align: center;
    font-family: "Be Vietnam Pro";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; 
    letter-spacing: 0.176px;
    display: flex;
    width: 336px;
    padding: 12px 65px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    background: #4880FF;
    border: none;
    cursor: pointer;
}

.pricing-card-blue{
    display: flex;
    flex-direction: column;
    padding: 50px 24px;
    align-items: flex-start;
    gap: 10px;
    border-radius: 14px;
    background: #4880FF;
    box-shadow: 0px 10px 56px 0px rgba(0, 0, 0, 0.05);   
}
.pricing-card-blue h1{
    width: 336px;
    color: #fff;
    text-align: center;
    font-family: "Be Vietnam Pro";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 133.333% */
}
.pricing-card-blue p{
    width: 336px;
    color: #fff;
    text-align: center;
    font-family: "Be Vietnam Pro";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
}

.pricing-card-blue h2{
    width: -webkit-fill-available;
    display: flex;
    justify-content: center;
    color: #fff;
    font-family: "Nunito Sans";
    font-size: 56px;
    font-style: normal;
    font-weight: 700;
    line-height: 60px; 
}
.pricing-card-blue button{
    flex-shrink: 0;
    color: #4880FF;
    text-align: center;
    font-family: "Be Vietnam Pro";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; 
    letter-spacing: 0.176px;
    display: flex;
    width: 336px;
    padding: 12px 65px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    background: #fff;
    border: none;
    cursor: pointer;
}

.not-acc-item{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: -10px;
    margin-bottom: 24px;
    border-radius: 4px;
    text-decoration: line-through;

}

.not-acc-text{
    color: #0c0047;
    text-align: right;
    font-family: "Nunito Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.084px;
    width: 306px;
    margin-right: 5px;
}
.feature-item-icon {
    width: 12px;
    height: 12px;
  }
  
  .toggle-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px; /* Adjust spacing as needed */
    margin-bottom: 20px; /* Adjust margin as needed */
  }
  
  .toggle-container span {
    font-size: 16px; /* Adjust font size as needed */
  }
  

@media (min-width: 768px) and (max-width: 1179px) {
    .background-img{
        background-size: 180% auto;
    }

    .info-part h1{
        color: #FFF;
        text-align: center;
        font-family: "Nunito Sans";
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: 47.813px; /* 132.815% */
        letter-spacing: 0.18px;
        text-transform: capitalize;
        width: 500.703px;
    }

    .info-part p {
        color: #FFF;
        text-align: center;
        font-family: "Nunito Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18.889px; /* 134.923% */
        letter-spacing: -0.241px;
        width: 525.495px;
        }
    
        .info-img {
            width: 800px;
            margin-top: 40px;
            border-radius: 15px;
            box-shadow: 20px 20px 50px -12px rgba(0, 0, 0, 0.20);
          }
          .features-text{
            font-size: 20px;
            letter-spacing: 0.1px;

          }
          .bookly-text{
            font-size: 20px;
            letter-spacing: 0.1px;
          }

}

@media (max-width: 767px) {
    .background-img{
        background-size: 250% auto;
    }

    .info-part h1{
        color: #FFF;
        text-align: center;
        font-family: "Nunito Sans";
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 26.381px; 
        letter-spacing: 0.1px;
        text-transform: capitalize;
        width: 216.336px;
    }
    .info-part p {
        color: #FFF;
        text-align: center;
        font-family: "Nunito Sans";
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.133px;
        width: 312.015px;
    }

    .info-img {
        width: 370px;
        height: 256px;
        margin-top: 40px;
        border-radius: 15px;
        box-shadow: 20px 20px 50px -12px rgba(0, 0, 0, 0.20);
    }

    .features-text{
        font-size: 20px;
        letter-spacing: 0.1px;
    }
    .bookly-text{    
        font-size: 20px;
        letter-spacing: 0.1px;
    }
    .features-p{
        padding: 0 80;
        font-size: 16px;
    }
    .payment-container{
        padding: 25px;
        display: flex;
        gap: 20px;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        align-items: center;
    }
    .dashboard-img{
        width: 360px;
        height: 250px;
        
        border-radius: 10.5px;
        background: #F8F9FD;  
    }
    .payment-text h1{
        font-size: 20px;    
        padding: 20px 0 0 180px;
    }
    .payment-text button{
        padding: 7px 20px;
    }
    .pricing-card-container {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    .pricing-card-white,
    .pricing-card-blue {
        display: flex;
        width: 326.016px;
        padding: 41.797px 20.063px;
        align-items: flex-start;
        gap: 8.359px;
    }

}
