.Login {
  background-color: #EFF5FF;
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  text-align: center;
}

.Register-box {
  display: flex;  
  justify-content: center;
  align-items: center;
  z-index: 1;
  width: 300px;
  /* height: 500px; */
  padding: 22px 56px; 
  border-radius: 32px;
  box-shadow: 0 8px 32px 0 #4285F426; 
  background: #FFFFFF;
}
.register-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.register-form {
  text-align: center;
  max-width: 300px; /* Adjust the width here */
  padding: 10px; /* Adjust the padding here */
  margin: auto;
  border-radius: 8px; /* Optional: Add rounded corners */
  background-color: #fff; /* Optional: Add background color */
}

.register-icon{
  height: 38px;
  width: 204px;
}

h1 {
  font-size: 18px; 
  margin-bottom: 10px; 
}

.site-form-item-icon {
  color: rgba(0, 0, 0, 0.25);
}


/* .ant-input-affix-wrapper-lg {
  height: 30px; 
} */
:where(.css-dev-only-do-not-override-3rel02).ant-input-affix-wrapper-lg {
  padding: 5px 11px;
} 


.forgot-password-link {
  color: black;
}

.forgot-password-link:hover {
  color: darkgray;
}

.create-account {
  margin-top: 10px;
  color: black;
}

.create-account a {
  color: #1890ff;
  text-decoration: none;
}

.create-account a:hover {
  text-decoration: underline;
}

.register-form .ant-form-item {
  margin-bottom: 6px; 
}

/* .register-form .ant-input,
.register-form .ant-input-password {
  padding: 2px 11px !important; 
} */

.register-form .ant-btn {
  height: 30px; 
  line-height: 1.4715; 
}
.register-form .ant-form-item-explain {
  margin-top: -4px; 
}

.register-form .ant-form-item-with-help {
  margin-bottom: 4px;
}

.tenant-prefix {
  color: #8c8c8c;
  background-color: #f5f5f5;
  padding: 4px 8px;
  border-radius: 4px;
  margin-right: 4px;
  font-size: 14px;
}

.tenant-suffix {
  font-size: 14px;
  padding: 0 8px;
}

.tenant-suffix.success {
  color: #52c41a !important;
}

.tenant-suffix.error {
  color: #ff4d4f !important;
}

.ant-select-lg {
  width: 100%;
}

.ant-select-selection-item {
  font-size: 14px;
}