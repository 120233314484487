/* Existing CSS */
.rbc-event.rbc-selected,
.rbc-day-slot .rbc-selected.rbc-background-event {
  background-color: #acc9df;
}

.rbc-off-range {
  color: #000;
}

.rbc-button-link {
  cursor: context-menu;
}



.pastDay {
  pointer-events: none;
  opacity: 0.5;
  cursor: auto;
}

.pastEvent {
  pointer-events: none;
  cursor: auto;
}
