.custom-tabs .ant-tabs-nav-wrap {
    display: flex;
    justify-content: space-between;
  }
  
  .custom-tabs .ant-tabs-tab {
    margin-right: 20px !important;
  }

  :where(.css-dev-only-do-not-override-3rel02).ant-input-search >.ant-input-group >.ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary):hover{
    color: #aaa;
  }

  /* :where(.css-dev-only-do-not-override-3rel02).ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover{
    background-color: #aaa;
  } */

  :where(.css-dev-only-do-not-override-3rel02).ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover,:where(.css-dev-only-do-not-override-3rel02).ant-input-search .ant-input:focus{
    border-color: #aaa;
  }
  :where(.css-dev-only-do-not-override-3rel02).ant-input-search .ant-input:hover{
    border-color: #aaa;
  }