.background-img-terms{
    margin: o;
    padding: o;
    background-image: url("../../assets/rightWhiteCircle.svg");
    background-repeat: no-repeat;
    background-size: 120% auto;
    background-position: 20% 0%;
}

.terms-container{
    padding: 20px 80px;
    direction: rtl;
}


@media (max-width: 500px) {
    .header-container{
        padding: 5px;
    }
    .terms-container{
        padding: 20px;
    }
}
