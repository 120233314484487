.container {
    display: grid;
    grid-template-columns: 66% 33%;
    align-items: center;
    padding: 22px;
  }
  
  .nav-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    margin-right: 17vh;
  }
  
  .nav-bar div {
    display: flex;
    gap: 10px; /* Adjust gap between buttons or links if needed */
  }
  
  .icon-header {
    width: 111px;
    height: 21px;
  }
  
  .menu-icon {
    display: none; /* Hide menu icon by default */
    font-size: 24px;

  }
  
  .nav-bar-mobile{
    display: none !important;
  }

  .nav-bar-content{
    color: #FFFFFF;
    display: flex !important;
  }

  .white-btn{
    display: inline-flex;
    padding: 12px 45px;
    font-weight: 700;

    font-size: medium;
    align-items: flex-start;
    gap: 10px;
    border-radius: 8px;
    background-color: #FFFFFF;
    border: none;
    cursor: pointer;
    color: #4880FF;
  }
  .blue-btn{
    border-radius: 8px;
    border: 1px solid #FFF;
    display: inline-flex;
    padding: 12px 45px;
    font-weight: 700;
    font-size: medium;

    align-items: flex-start;
    gap: 10px;
    background: inherit;
    color: #FFFFFF;
    cursor: pointer;
  }

  @media (max-width: 1050px) {

    .nav-bar{
        margin-right: 6vh;

    }
    .white-btn{
      display: inline-flex;
      padding: 7.083px 26.563px;
      align-items: center;
      gap: 5.903px;
      color: #4880FF;
      font-size: 8.264px;
      font-style: normal;
      font-weight: 700;
      line-height: 12.986px;
      letter-spacing: -0.241px;
      height: fit-content;
  }
  .blue-btn{
    display: inline-flex;
    padding: 7.083px 26.563px;
    align-items: flex-start;
    gap: 5.903px;
    border-radius: 4.722px;
    border: 0.59px solid #FFF;
    color: #FFF;
    font-family: "Nunito Sans";
    font-size: 8.264px;
    font-style: normal;
    font-weight: 700;
    line-height: 12.986px; /* 157.143% */
    letter-spacing: -0.241px;
    
  }
  }

  @media (max-width: 768px) {
    /* Tablet view */
    .container {
      grid-template-columns: 50% 50%;
    }
    .nav-bar{
        margin-right: -8vh;

    }
  }

  
  @media (max-width: 480px) {
    /* Mobile view */
    .container {
      grid-template-columns: 50% 50%;
    }
  
    .icon-header {
      width: 91px;
      height: 21px;
      position: absolute;
      top: 21px;
      right: 5px;
    }
  
    .nav-bar {
      display: none; /* Hide nav-bar by default on mobile */
    }
  
    .nav-bar.active {
        display: flex;
        position: absolute;
        top: 44px;
        left: 0;
        background-color: white;
        flex-direction: column;
        gap: 10px 0;
        padding: 15px 0;
        width: 100vw; /* Full width of the viewport */
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }
  
    .menu-icon {
      display: block; /* Show menu icon on mobile */
      position: absolute;
      left: 10px;
      top: 13;
    }
    .nav-bar-mobile{
        display: flex !important;
        justify-content: space-between;
        align-items: center;
        gap: 5px;     
        flex-direction: column;
   
    }
    .nav-bar {
        display: none;
        justify-content: space-between;
        align-items: center;
        gap: 5px;
        margin-right: 14vh;
      }

      .nav-bar-content{
        display: none !important;
      }
  }
  
  .whatsapp-icon {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 50px;
    height: 50px;
    cursor: pointer;
    z-index: 1000; /* Ensure it is above other elements */
  }

  .whatsapp-icon img {
    width: 100%;
    height: 100%;
  }
  